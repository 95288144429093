// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatureCard-module--root--2o9Zx";
export var root__long = "PlasmicFeatureCard-module--root__long--1alJX";
export var freeBox__v93Fd = "PlasmicFeatureCard-module--freeBox__v93Fd--2OAss";
export var freeBox__v3TTh = "PlasmicFeatureCard-module--freeBox__v3TTh--2oE-E";
export var svg__ilQht = "PlasmicFeatureCard-module--svg__ilQht--1F5A5";
export var freeBox___8K7Q = "PlasmicFeatureCard-module--freeBox___8K7Q--3aeiY";
export var freeBox__long___8K7Q6JDlY = "PlasmicFeatureCard-module--freeBox__long___8K7Q6JDlY--2fC42";
export var slotTitle = "PlasmicFeatureCard-module--slotTitle--f-M6A";
export var slotDescription = "PlasmicFeatureCard-module--slotDescription--ek_se";
export var slotDescription__long = "PlasmicFeatureCard-module--slotDescription__long--1CZ_e";
export var freeBox__k8DHu = "PlasmicFeatureCard-module--freeBox__k8DHu--23BSm";
export var freeBox__long__k8DHu6JDlY = "PlasmicFeatureCard-module--freeBox__long__k8DHu6JDlY--2Wetm";
export var svg__nXrRt = "PlasmicFeatureCard-module--svg__nXrRt--1zcd7";