// extracted by mini-css-extract-plugin
export var root = "PlasmicPricing-module--root--Kf0oH";
export var freeBox__r1ACh = "PlasmicPricing-module--freeBox__r1ACh--2Hn9W";
export var header = "PlasmicPricing-module--header--3rvc5";
export var section__f1Mgj = "PlasmicPricing-module--section__f1Mgj--2vPWf";
export var freeBox__nXzWx = "PlasmicPricing-module--freeBox__nXzWx--ckXsJ";
export var plan__pYett = "PlasmicPricing-module--plan__pYett--18AU2";
export var freeBox__zgRpp = "PlasmicPricing-module--freeBox__zgRpp--1BVpz";
export var freeBox__mimds = "PlasmicPricing-module--freeBox__mimds--1SRfz";
export var bullet__blsO5 = "PlasmicPricing-module--bullet__blsO5--1QUU6";
export var bullet__fuv0C = "PlasmicPricing-module--bullet__fuv0C--rQe14";
export var bullet__vPjmn = "PlasmicPricing-module--bullet__vPjmn--3h05c";
export var plan__g2Es1 = "PlasmicPricing-module--plan__g2Es1--1fgHV";
export var freeBox__xzU4F = "PlasmicPricing-module--freeBox__xzU4F--UgI6z";
export var freeBox__uwHv4 = "PlasmicPricing-module--freeBox__uwHv4--1TjW5";
export var bullet__gjvvc = "PlasmicPricing-module--bullet__gjvvc--1VqCN";
export var bullet___1C8FU = "PlasmicPricing-module--bullet___1C8FU--3tIEt";
export var bullet__xCp5 = "PlasmicPricing-module--bullet__xCp5--1TQia";
export var bullet___9Y8Ic = "PlasmicPricing-module--bullet___9Y8Ic--16njq";
export var plan__xxe34 = "PlasmicPricing-module--plan__xxe34--1W9RQ";
export var freeBox___8FGhX = "PlasmicPricing-module--freeBox___8FGhX--3a7KA";
export var freeBox__tnUSb = "PlasmicPricing-module--freeBox__tnUSb--3WOQq";
export var bullet__s3S9I = "PlasmicPricing-module--bullet__s3S9I--3RTwX";
export var bullet__xvxwE = "PlasmicPricing-module--bullet__xvxwE--10BCW";
export var bullet__mkewl = "PlasmicPricing-module--bullet__mkewl--J7Jys";
export var section___6IGF = "PlasmicPricing-module--section___6IGF--SVy4n";
export var faq__wym4 = "PlasmicPricing-module--faq__wym4--1TY9b";
export var faq__ksCxT = "PlasmicPricing-module--faq__ksCxT--3qH-p";
export var faq__k6XhB = "PlasmicPricing-module--faq__k6XhB--12Jks";
export var footer = "PlasmicPricing-module--footer--zhosD";