// extracted by mini-css-extract-plugin
export var root = "PlasmicFeatures-module--root--2CVwz";
export var freeBox__zeIjv = "PlasmicFeatures-module--freeBox__zeIjv--3NreQ";
export var header = "PlasmicFeatures-module--header--aLgU4";
export var section = "PlasmicFeatures-module--section--YTT7P";
export var freeBox__jMvvw = "PlasmicFeatures-module--freeBox__jMvvw--2l-v6";
export var freeBox__eqBrf = "PlasmicFeatures-module--freeBox__eqBrf--1Ih9J";
export var freeBox__dDUv = "PlasmicFeatures-module--freeBox__dDUv--3GjJa";
export var freeBox__t5SU = "PlasmicFeatures-module--freeBox__t5SU--aIvPT";
export var bullet___6Bue = "PlasmicFeatures-module--bullet___6Bue--3qAYq";
export var bullet___4PJxe = "PlasmicFeatures-module--bullet___4PJxe--1PX_x";
export var bullet__xZ0F = "PlasmicFeatures-module--bullet__xZ0F--29qsP";
export var freeBox__brUe5 = "PlasmicFeatures-module--freeBox__brUe5--1r4o6";
export var freeBox__rm8Aq = "PlasmicFeatures-module--freeBox__rm8Aq--2psmc";
export var freeBox__cfijt = "PlasmicFeatures-module--freeBox__cfijt--2S6Kl";
export var img___4LcXx = "PlasmicFeatures-module--img___4LcXx--2vaou";
export var img___8XjHg = "PlasmicFeatures-module--img___8XjHg--Q74Dg";
export var freeBox__rkHoV = "PlasmicFeatures-module--freeBox__rkHoV--1y9xt";
export var freeBox__y0450 = "PlasmicFeatures-module--freeBox__y0450--2HN9T";
export var img__rspMt = "PlasmicFeatures-module--img__rspMt--3_Vsh";
export var img__hpwlf = "PlasmicFeatures-module--img__hpwlf--8DCKu";
export var freeBox__ctI0T = "PlasmicFeatures-module--freeBox__ctI0T--2TGT3";
export var freeBox__hcvDn = "PlasmicFeatures-module--freeBox__hcvDn--26wIH";
export var freeBox__wCf0A = "PlasmicFeatures-module--freeBox__wCf0A--3hV9B";
export var bullet__ve3HM = "PlasmicFeatures-module--bullet__ve3HM--1U8M3";
export var bullet__zYf4J = "PlasmicFeatures-module--bullet__zYf4J--1A3Li";
export var bullet__s6S7J = "PlasmicFeatures-module--bullet__s6S7J--1kaxV";
export var freeBox__ls3L = "PlasmicFeatures-module--freeBox__ls3L--2F8sH";
export var freeBox__iDc4C = "PlasmicFeatures-module--freeBox__iDc4C--15o0G";
export var homeCta = "PlasmicFeatures-module--homeCta--_VZ3o";
export var footer = "PlasmicFeatures-module--footer--3Mue7";