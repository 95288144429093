// extracted by mini-css-extract-plugin
export var root = "PlasmicFooter-module--root--2Nkxw";
export var freeBox__muwCv = "PlasmicFooter-module--freeBox__muwCv--3bJD-";
export var freeBox__bcXg1 = "PlasmicFooter-module--freeBox__bcXg1--2dYdf";
export var freeBox___2VsDa = "PlasmicFooter-module--freeBox___2VsDa--3FY4r";
export var iconLink__drWut = "PlasmicFooter-module--iconLink__drWut--2gIdC";
export var svg__wAh3B = "PlasmicFooter-module--svg__wAh3B--3_X6u";
export var freeBox__fNgj1 = "PlasmicFooter-module--freeBox__fNgj1--JE66k";
export var link__exjC = "PlasmicFooter-module--link__exjC--rckT7";
export var freeBox__m2GoO = "PlasmicFooter-module--freeBox__m2GoO--1uqEv";
export var link__c2OaI = "PlasmicFooter-module--link__c2OaI--1cyMM";
export var freeBox__jWfqm = "PlasmicFooter-module--freeBox__jWfqm--1xHCu";
export var freeBox__nMdqw = "PlasmicFooter-module--freeBox__nMdqw--3kKA4";
export var freeBox__cSdM = "PlasmicFooter-module--freeBox__cSdM--2Jdo0";
export var link__xYmfL = "PlasmicFooter-module--link__xYmfL--60ueh";
export var link__j2O7 = "PlasmicFooter-module--link__j2O7--HN6HV";
export var link__gFmlx = "PlasmicFooter-module--link__gFmlx--1j1Vb";
export var link__h6XZh = "PlasmicFooter-module--link__h6XZh--2VROk";
export var link___01I0 = "PlasmicFooter-module--link___01I0--1kLQt";
export var freeBox__nAcwB = "PlasmicFooter-module--freeBox__nAcwB--2zVik";
export var freeBox__mkog7 = "PlasmicFooter-module--freeBox__mkog7--HURlO";
export var link__er2Y4 = "PlasmicFooter-module--link__er2Y4--PUDUS";
export var link__m1SJ8 = "PlasmicFooter-module--link__m1SJ8--2PLha";
export var link__oBrJj = "PlasmicFooter-module--link__oBrJj--fgame";
export var link__ttQ9O = "PlasmicFooter-module--link__ttQ9O--1o35f";
export var link__oXnDp = "PlasmicFooter-module--link__oXnDp--1iqIh";
export var freeBox__fxAWr = "PlasmicFooter-module--freeBox__fxAWr--1J9Xn";
export var freeBox__qZuna = "PlasmicFooter-module--freeBox__qZuna--1qfNZ";
export var link__cuQ7B = "PlasmicFooter-module--link__cuQ7B--39HAc";
export var link__b3Xhe = "PlasmicFooter-module--link__b3Xhe--3tMGh";
export var link__cAb3F = "PlasmicFooter-module--link__cAb3F--2HSl8";
export var link__p1RqI = "PlasmicFooter-module--link__p1RqI--1Xd0j";
export var link___6Xle2 = "PlasmicFooter-module--link___6Xle2--hyhzC";
export var freeBox__xGbDc = "PlasmicFooter-module--freeBox__xGbDc--JtV4u";
export var freeBox__fPmic = "PlasmicFooter-module--freeBox__fPmic--1iKRq";
export var freeBox___0YzT = "PlasmicFooter-module--freeBox___0YzT--2Rufx";
export var freeBox__ftt6P = "PlasmicFooter-module--freeBox__ftt6P--1BOaE";
export var textbox = "PlasmicFooter-module--textbox--2iVbX";
export var freeBox__psoM = "PlasmicFooter-module--freeBox__psoM--2bEjk";
export var button = "PlasmicFooter-module--button--Lj5uW";
export var svg__qvbXb = "PlasmicFooter-module--svg__qvbXb--3aQrn";
export var freeBox__rLjUl = "PlasmicFooter-module--freeBox__rLjUl--1Ssoz";
export var freeBox___30FWw = "PlasmicFooter-module--freeBox___30FWw--3toDb";
export var freeBox__fhxOq = "PlasmicFooter-module--freeBox__fhxOq--xeel1";
export var link__qEfQo = "PlasmicFooter-module--link__qEfQo--1s3Co";
export var link__j94Iv = "PlasmicFooter-module--link__j94Iv--3_G76";
export var freeBox__m7S98 = "PlasmicFooter-module--freeBox__m7S98--2e6aS";
export var freeBox__tFVjG = "PlasmicFooter-module--freeBox__tFVjG--Av1UL";
export var iconLink__qS7P6 = "PlasmicFooter-module--iconLink__qS7P6--14iL9";
export var svg__fBlwb = "PlasmicFooter-module--svg__fBlwb--2Avlg";
export var iconLink__wePl7 = "PlasmicFooter-module--iconLink__wePl7--2iWaY";
export var svg__jp16Q = "PlasmicFooter-module--svg__jp16Q--3Ni15";
export var iconLink__jqgp8 = "PlasmicFooter-module--iconLink__jqgp8--23f9A";
export var svg__ygoLa = "PlasmicFooter-module--svg__ygoLa--2eSED";