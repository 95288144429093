// extracted by mini-css-extract-plugin
export var root = "PlasmicHomeCta-module--root--2Am2m";
export var freeBox__uehHy = "PlasmicHomeCta-module--freeBox__uehHy--facOj";
export var freeBox__cftej = "PlasmicHomeCta-module--freeBox__cftej--3jSc2";
export var freeBox__kNinR = "PlasmicHomeCta-module--freeBox__kNinR--287jG";
export var freeBox__pItdp = "PlasmicHomeCta-module--freeBox__pItdp--1pEjS";
export var textbox = "PlasmicHomeCta-module--textbox--1pm9S";
export var linkButton = "PlasmicHomeCta-module--linkButton--2rfYO";
export var freeBox__zGn4L = "PlasmicHomeCta-module--freeBox__zGn4L--dse6J";
export var freeBox__texUr = "PlasmicHomeCta-module--freeBox__texUr--1xOFe";
export var svg = "PlasmicHomeCta-module--svg--1sl35";