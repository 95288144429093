// extracted by mini-css-extract-plugin
export var root = "PlasmicTopSection-module--root--2B2t-";
export var freeBox__dEk8I = "PlasmicTopSection-module--freeBox__dEk8I--1SgyK";
export var freeBox__cmqWh = "PlasmicTopSection-module--freeBox__cmqWh--fiqYl";
export var freeBox__cQn5B = "PlasmicTopSection-module--freeBox__cQn5B--nchAL";
export var freeBox__lUzb = "PlasmicTopSection-module--freeBox__lUzb--3MvRv";
export var freeBox__y6Dka = "PlasmicTopSection-module--freeBox__y6Dka--369aj";
export var freeBox___4X3B3 = "PlasmicTopSection-module--freeBox___4X3B3--rkh-P";
export var freeBox__dnjuN = "PlasmicTopSection-module--freeBox__dnjuN--2YMtz";
export var linkButton__tjnrY = "PlasmicTopSection-module--linkButton__tjnrY--1_5AA";
export var linkButton__hrZRu = "PlasmicTopSection-module--linkButton__hrZRu--1U06z";
export var freeBox__e0ZRg = "PlasmicTopSection-module--freeBox__e0ZRg--3W4ZR";
export var img = "PlasmicTopSection-module--img--TX3Qd";