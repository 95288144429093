// extracted by mini-css-extract-plugin
export var root = "PlasmicPlan-module--root--1Vhcn";
export var freeBox___2VtYy = "PlasmicPlan-module--freeBox___2VtYy--1sS3f";
export var freeBox__kjuJh = "PlasmicPlan-module--freeBox__kjuJh--3tDcz";
export var slotName = "PlasmicPlan-module--slotName--3Vdmj";
export var freeBox__wk8 = "PlasmicPlan-module--freeBox__wk8--dfit-";
export var freeBox__pQ5Ru = "PlasmicPlan-module--freeBox__pQ5Ru--1H7Fq";
export var bullet__c0BAn = "PlasmicPlan-module--bullet__c0BAn--2yZdL";
export var bullet__zJpUf = "PlasmicPlan-module--bullet__zJpUf--18XEa";
export var bullet__kp2Li = "PlasmicPlan-module--bullet__kp2Li--2cVuU";
export var freeBox__xoR5V = "PlasmicPlan-module--freeBox__xoR5V--1Qx96";
export var svg__mPbCh = "PlasmicPlan-module--svg__mPbCh--2ZGNR";